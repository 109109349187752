export const TITLES = [
    {
      shortName: "CAPT",
      salary: "-",
      engName: "Captain",
      viName: "Thuyền Trưởng",
    },
    {
      shortName: "CO",
      salary: "-",
      engName: "Chief Officer",
      viName: "Đại phó",
    },
    {
      shortName: "2O",
      salary: "-",
      engName: "Second Officer",
      viName: "Phó 2",
    },
    {
      shortName: "3O",
      salary: "39,650",
      engName: "Third Officer",
      viName: "Phó 3",
    },
    {
      shortName: "CE",
      salary: "63,200",
      engName: "Chief Engineer",
      viName: "Máy Trưởng",
    },
    {
      shortName: "2E",
      salary: "58,000",
      engName: "Second Engineer",
      viName: "Máy 2",
    },
    {
      shortName: "3E",
      salary: "48,000",
      engName: "Third Engineer",
      viName: "Máy 3",
    },
    {
      shortName: "4E",
      salary: "39,650",
      engName: "Fourth Engineer",
      viName: "Máy 4",
    },
    {
      shortName: "ETO",
      salary: "-",
      engName: "Electric Technical Officer",
      viName: "Sỹ quan điện",
    },
    {
      shortName: "ETR",
      salary: "28,870",
      engName: "Electric Technical Rating",
      viName: "Thợ điện",
    },
    {
      shortName: "BSN",
      salary: "-",
      engName: "BOSUN",
      viName: "Thuỷ thủ trưởng",
    },
    {
      shortName: "ABD",
      salary: "-",
      engName: "Able Seaman Deck",
      viName: "Thuỷ thủ lành nghề bong",
    },
    {
      shortName: "ABE",
      salary: "19,870",
      engName: "Able Seaman Engineer",
      viName: "Thuỷ thủ lành nghề máy",
    },
    {
      shortName: "OS",
      salary: "-",
      engName: "Ordinary Seaman",
      viName: "Thuỷ thủ phổ thông",
    },
    {
      shortName: "COOK",
      salary: "-",
      engName: "Cook",
      viName: "Đầu bếp",
    },
    {
      shortName: "MESS",
      salary: "-",
      engName: "Mesman",
      viName: "Người phục vụ",
    },
  ];

export const STATE = {
    CONNECT:'CONNECT',
    FEE_DISAGREED:'FEE_DISAGREED',
    FEE_AGREED:'FEE_AGREED',
    USER_REFUSE:'USER_REFUSE',
    OWNER_REFUSE:'OWNER_REFUSE',
    DOC_PREPARING:'DOC_PREPARING',
    DOC_COMPLETED:'DOC_COMPLETED',
    ON_BOARD:'ON_BOARD',
    OFF_BOARD:'OFF_BOARD',
    OTHERS:'OTHERS'
};

export const STATES = [
    {
        id: STATE.CONNECT,
        text: 'Liên Lạc'
    },
    {
        id: STATE.FEE_DISAGREED,
        text: 'Từ Chối Phí'
    },
    {
        id: STATE.FEE_AGREED,
        text: 'Đồng Ý Phí'
    },
    {
        id: STATE.USER_REFUSE,
        text: 'TV Từ Chối'
    },
    {
        id: STATE.OWNER_REFUSE,
        text: 'Tàu Từ Chối'
    },
    {
        id: STATE.DOC_PREPARING,
        text: 'Đang Làm Hồ Sơ'
    },
    {
        id: STATE.DOC_COMPLETED,
        text: 'Xong Hồ Sơ'
    },
    {
        id: STATE.ON_BOARD,
        text: 'Đang Đi Tàu'
    },
    {
        id: STATE.OFF_BOARD,
        text: 'Xuống Tàu'
    },
    {
        id: STATE.OTHERS,
        text: 'Không Rõ'
    }
];

export const ACTION = {
  ADD: 'ADD',
  EDIT: 'EDIT',
  VIEW: 'VIEW'
}

